import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PageLoading from "components/Loading/PageLoading";

const HomePressPage = lazy(() => import("pages/home/HomePressPage"));
const ReferralPage = lazy(() => import("pages/home/ReferralPage"));
const Page404 = lazy(() => import("components/Page404"));
const QRCodeGC = lazy(() => import("pages/QRCodeGC"));
const QRCodeOne = lazy(() => import("pages/QRCodeOne"));
const PrivacyPage = lazy(() => import("pages/terms_privacy/PrivacyPage"));
const TermsPage = lazy(() => import("pages/terms_privacy/TermsPage"));
const WyrePage = lazy(() => import("pages/terms_privacy/Wyre"));
const FAQsPage = lazy(() => import("pages/terms_privacy/FAQs"));
const PasswordlessLogin = lazy(() => import("pages/PasswordlessLogin"));
const PressedPage2 = lazy(() => import("pages/pressed/_p2/PressedPage2"));

const AppRoutes = () => {
  const windowHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--window-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", windowHeight);
  windowHeight();
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoading />}>
        <Switch>
          <Route exact path="/">
            <HomePressPage order={0} />
          </Route>
          <Route path="/referral">
            <ReferralPage />
          </Route>
          <Route path="/passwordless/login">
            <PasswordlessLogin />
          </Route>
          <Route path="/gc">
            <QRCodeGC />
          </Route>
          <Route path="/faqs">
            <FAQsPage />
          </Route>
          <Route path="/tos">
            <TermsPage />
          </Route>
          <Route path="/wyre_ua">
            <WyrePage />
          </Route>
          <Route path="/privacy">
            <PrivacyPage />
          </Route>
          <Route path="/group_invite">
            <QRCodeOne />
          </Route>
          <Route path="/join_dogfood">
            <QRCodeOne />
          </Route>
          <Route path="/p2">
            <PressedPage2 />
          </Route>
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
